.confirmation.text {
  color: #585858;
  font-size: 18px;
  font-weight: 600;
  padding: 40px;
  text-align: center;
  .question-wrapper {
    margin-bottom: 0;
  }
}

.confirm-message {
  color: $quaternary;
  font-weight: bold;
  text-align: center;
  width: 100%;
  &.backgrounded {
    background-color: #f5f4f0;
    padding: 60px 0;
    margin: 60px 0;
  }
}

.small-text {
  font-size: 13px;
}

.text-left {
  text-align: left !important;
  justify-content: flex-start !important;
}
.text-center {
  text-align: center !important;
  justify-content: center !important;
}
.text-right {
  text-align: right !important;
  justify-content: flex-end !important;
}
