.steps {
  @extend %flexbox;
  @include flex-direction(column);
  padding: $mobile-steps-padding;
  margin: $mobile-steps-margin;
  @if $isIconStyle == 'number' {
    counter-reset: section;
  }
  @include media-breakpoint-up(sm) {
    @include flex-direction(row);
    @include justify-content(space-between);
    padding: $steps-padding;
    margin: $steps-margin;
  }

  .step {
    @extend %flexbox;
    @include flex(1 0 auto);
    position: relative;
    padding-bottom: $mobile-step-padding-bottom;
    @include media-breakpoint-up(sm) {
      @include flex-direction(row);
      padding-bottom: 0;
    }
    &:last-child {
      @include flex(none);
      padding-bottom: 0;
      .line {
        display: none;
      }
    }
    .icon-block {
      @extend %flexbox;
      @include align-items(center);
      position: relative;
      padding: 0;
      z-index: 2;
      @include media-breakpoint-up(sm) { @include flex-direction(column); }
      i {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        width: $step-icon-block-width;
        height: $step-icon-block-height;
        border-radius: $step-icon-block-radius;
        background-color: $step-bg-color;
        margin-right: 14px;
        @if $isIconStyle == 'number' {
          &:before {
            counter-increment: section;
            content: "" counter(section);
          }
        }
        @if $isIconStyle == 'sprite' {
          background-image: $step-icon-url;
          background-position: #{$step-icon-block-width * -2} #{$step-icon-block-height * 0};
        } @else {
          color: $step-icon-color;
          font-size: $step-icon-size;
          font-style: $step-icon-style;
        }
        @include media-breakpoint-up(sm) {
          margin-right: 0;
        }
      }
      span {
        color: $step-text-color;
        font-size: $step-text-size;
        font-weight: $step-text-weight;
        text-align: $step-text-align;
        @if $isStepTextWidthConstrained == 'yes' {
          @include media-breakpoint-up(sm) {
            width: $step-text-width
          }
        } @else {
          white-space: nowrap;
        }
        @include media-breakpoint-up(sm) {
          position: absolute;
          top: ($step-icon-block-width + 6px);
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
      &.router-link-active {
        i {
          background-color: $step-active-bg-color;
          @if $isIconStyle == 'sprite' {
            background-position: #{$step-icon-block-width * 0} #{$step-icon-block-height * 0};
          } @else {
            color: $step-active-icon-color;
          }
        }
        span {
          color: $step-active-text-color;
          font-weight: $step-active-text-weight;
        }
        + .line { background-color: $step-active-line-bg-color; }
      }
    }
    .line {
      background-color: $step-bg-color;
      width: 1px;
      height: 100%;
      position: absolute;
      top: $step-icon-block-width;
      left: calc(#{$step-icon-block-width} / 2);

      @include media-breakpoint-up(sm) {
        width: calc(100% - #{$step-icon-block-width});
        height: 1px;
        position: absolute;
        top: calc(#{$step-icon-block-width} / 2);
        left: #{$step-icon-block-width};
      }
    }
    &.validated {
      i {
        color: $step-achieve-icon-color;
        background-color: $step-achieve-bg-color;
        @if $isIconStyle == 'sprite' {
          background-position: #{$step-icon-block-width * -1} #{$step-icon-block-height * 0};
        }
      }
      span {
        color: $step-achieve-text-color;
        font-weight: $step-achieve-text-weight;
      }
      .line { background-color: $step-achieve-line-bg-color; }
    }
    &.active, &.current {
      i {
        background-color: $step-active-bg-color;
        @if $isIconStyle == 'sprite' {
          background-position: #{$step-icon-block-width * 0} #{$step-icon-block-height * 0};
        } @else {
          color: $step-active-icon-color;
        }
      }
      span {
        color: $step-active-text-color;
        font-weight: $step-active-text-weight;
      }
      + .line { background-color: $step-active-line-bg-color; }
    }
  }
}
