.dropzone {
  background-color: $input-bg;
  border: $input-border-color solid $input-border-width;
  border-radius: $input-border-radius;
  form .btn {
    @include flex-direction(column);
    cursor: pointer;
    i {
      font-size: 40px;
    }
    span {
      font-style: italic;
      font-size: 13px;
    }
  }
  .files-list {
    .btn {
      @include align-items(center);
      position: relative;
      span {
        text-align: left;
      }
      i {
        font-size: 40px;
      }
    }
    .remove {
      @extend %flexbox;
      border-radius: 100%;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
