header {
  background-color: $header-bg-color;
  padding: $header-padding;
}

.subheader {
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 36px;
  }
}
.subheader-title {
  color: $subheader-title-color;
  font-size: $subheader-title-size;
  font-weight: $subheader-title-weight;
  margin: $subheader-title-margin;
  text-align: center;
  max-width: 100%;
  @include media-breakpoint-up(sm) {
    margin-bottom: 74px;
  }
  @if $isTitleHorizontalLines == 'yes' {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    div {
      margin: 0 $subheader-title-lines-margin;
    }
    &:before, &:after {
      content: "";
      @include flexbox();
      flex-grow: $subheader-title-lines-flex-grow;
      flex-basis: $subheader-title-lines-flex-basis;
      height: $subheader-title-lines-height;
      background-color: $subheader-title-lines-color;
      @if $isTitleHorizontalLinesBordered == 'yes' {
        border-bottom: $subheader-title-lines-border;
      }
    }

  }
}
