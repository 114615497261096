// Header
$header-padding: 0;
$header-bg-color: transparent;

// Subheader
$mobile-subheader-margin: 20px;
$subheader-margin: 36px;

  // Subheader Title

  $subheader-title-color: #444444;
  $subheader-title-size: 24px;
  $subheader-title-weight: normal;
  $subheader-title-margin: 38px 0 20px;

  $isTitleHorizontalLines: 'yes'; // yes or no
  $isTitleHorizontalLinesBordered: 'yes';// yes or no line with border
    $subheader-title-lines-height: 2px;
    $subheader-title-lines-margin: 40px;
    $subheader-title-lines-flex-grow: 1; // 1 => Toute la place || 0 => Taille du flex basis
    $subheader-title-lines-flex-basis: 0; // 0 => Pour grow 1  || XXpx => Taille exacte
    $subheader-title-lines-color: #d1d1cf;
    $subheader-title-lines-border: 1px solid #fff;
